import React from 'react'
import { Link } from 'gatsby'
import './header.css'
import styled from 'react-emotion'

const StyledNav = styled('nav')`
  margin-top: 15px;
`
// TODO: Add active link highlight logic
const Header = ({ siteTitle }) => (
  <div
    style={{
      marginBottom: '1.45rem',
    }}
  >
    <StyledNav>
      <ul className="nav-list">
        <li className="list-item">
          <Link to="/" activeClassName="active">
            Home
          </Link>
        </li>
        <li className="list-item">
          <Link to="/projects" activeClassName="active">
            Projects
          </Link>
        </li>
        <li className="list-item">
          <Link to="/contact" activeClassName="active">
            Contact
          </Link>
        </li>
      </ul>
    </StyledNav>
  </div>
)

export default Header
